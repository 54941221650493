body {
  margin: 0;
  background-color: #36393f;
}

h1 {
  font-size: 2vh;
  padding: 10px 0;
  border-bottom: 1px solid black;
  color: #b1b1b1;
}

h2 {
  font-size: 1.4vh;
}

.container {
  max-width: 1200px;
  margin: auto;
}

.item {
  padding: 10px 0;
}

.item-name {
  font-size: 1.2em;
}

.item-id {
  font-size: 1em;
  color: rgb(194, 194, 194);
}

.default {
  color: white;
}